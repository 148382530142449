@charset "UTF-8";
.omb_login .omb_socialButtons a {
	color: white;
	opacity:0.9;
	&:hover {
	opacity:1;    	
	}
}
.omb_btn-facebook {background: #3b5998;}
.omb_btn-twitter {background: #00aced;}
.omb_btn-git {background: #000;}


.loginOr {
	position: relative;
	font-size: 1.5em;
	color: #aaa;
	margin-top: 1em;
	margin-bottom: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	hr {
		background-color: #cdcdcd;
		height: 1px;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
	span {
		display: block;
		position: absolute;
		left: 50%;
		top: -0.2em;
		margin-left: -1.5em;
		background-color: white;
		width: 3em;
		text-align: center;
	}	
}

.help-block {
    color: red;
}
@media (min-width: 768px) {
    .forgotPwd {
        text-align: right;
		margin-top:10px;
 	}		
}