@charset "UTF-8";
//guide
.step {
	padding: 60px 20px;
	&:nth-child(2n){
		background-color: lighten($baseColor,65%);
	}
	&:nth-child(2n+1){
		background-color: #f7f7f7;
	}
	.row {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
	}
	span.st-number {
		background-color: $baseColor;
		padding: 5px 5px;
		font-size: 22px;
		color: #ffffff;
		margin-right: 10px;
    	border-radius: 50%;
		}
	h2 {
	    margin-bottom: 15px;
	}
}
.guide-buttons ul li{
	color: $baseColor;
	width: 400px;
	text-align: center;
	line-height: 50px;
	border: 1px solid $baseColor;
	position: relative;
	&:hover{
		cursor: pointer;
	}
	&.active{
		color: $baseColor;
		background-color: #FFF;
		&:after, &:before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}
		&:after {
			border-color: rgba(0, 0, 0, 0);
			border-top-color: #fff;
			border-width: 15px;
			margin-left: -15px;
		}
		&:before {
			border-color: rgba(0, 0, 0, 0);
			border-top-color: darken($baseColor,30%);
			border-width: 16px;
			margin-left: -16px;
		}
	}
}

//question 
.question {
	h2{
		margin-top: 80px;
	}
	dl{
		border-bottom: 1px dotted $baseColor;
		padding: 30px 0;
		dt{
			color: $baseColor;
			margin-bottom: 20px;
			padding-left: 3em;
			position: relative;
				&::before{
				content: "Q";
				background: $baseColor;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: inline-block;
				color: #fff;
				line-height: 30px;
				text-align: center;
				font-size: 1.2em;
				font-weight: lighter;
				position: absolute;
				left: 0;
				top: -10px;
				}	
				&::after{
					content: "▶";
					position: absolute;
					left: 26px;
					font-size: 10px;
					top: 0px;
				}
		}
		dd{
			padding-left: 3em;
			position: relative;
			line-height: 1.4;
			&::before{
				content: "A";
				background: lighten($baseColor,50%);
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: inline-block;
				color: #fff;
				line-height: 30px;
				text-align: center;
				font-size: 1.2em;
				font-weight: lighter;
				position: absolute;
				left: 0;
			}
			&::after{
				content: "▶";
				position: absolute;
				left: 26px;
				font-size: 10px;
				top: 9px;
				color: lighten($baseColor,50%);
			}
		}
	}//dl
}//.question

#poricy{
	padding-bottom: 60px;
	h3{
		text-align: left;
		font-size: 1.2em;
		margin: 30px 0 10px;

	}
}


