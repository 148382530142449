@charset "UTF-8";
#mentorDetail{
	margin-top: 20px;
	.prof{
		margin-top:20px;
	}
	.skill-name dl 
	{
		margin-bottom: 10px;
		font-size: 1.2em;
		dd:nth-child(2){
				margin:0 20px;
		}
	}
	.price {
		font-weight: bold;
		font-size: 1.2em;
	}
}

.mentor_name{
	h2 strong{
		font-size: 35px;
	}
}


#skillInfo{
	thead{
		tr{
			background-color: #eaeaea;
			th{
				text-align: center;
			}
		}
	}
	tbody{background-color: #fff;}
	td:nth-child(2),td:first-child{
		text-align: center;
		min-width: 85px;
	}
	td:nth-child(4){
		min-width: 128px;
	}
}

#ableCards{
	.close{
		transform: rotate(45deg);
	    position: relative;
		top: -10px;
		right: -10px;
	}
}

.rating {
	margin: 5px auto 20px;
	.star {
		color: $baseColor;
		font-size: 20px;
	}
}
.favolite_mark {
	color: #ccc;
	&.active { 
		color: #e46773;
	}
}