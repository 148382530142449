@charset "UTF-8";
#payjp_checkout_box{
	display: none;
}
#chargePoint{
 	ul li{
		background: #ffde66;
		border-radius: 10px;
		padding: 10px;
		box-shadow: 3px 3px 5px 0px #9a9a9a;
		p{
			font-weight: bold;
			text-shadow: 2px 2px 2px #fff;
		}
		p.point_no{
			font-size: 1.4em;
		}
		&:hover{
			cursor: pointer;
			background: darken(#ffde66,15%);
		}
	}
}
