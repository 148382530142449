@charset "UTF-8";

.calendar{
    max-width: 450px;
}
.selectDateTime{
    background: #fff;
    padding: 30px 150px 50px;
    top: 12%;
    position: relative;
    h3 {
        font-size: 20px;
    }
    .ui-datepicker-header {
        background: #EAEAEA;
        color: #333;
        font-weight: bold;
        line-height: 30px;
    }
}
.rsv_form p{
    margin: 10px 0;
}

.ui-datepicker {
  display: block;
  text-align: center;
  font-family: "Helvetica";
  color: #666;
  background: #FAFAFA;
}

.ui-datepicker-prev, .ui-datepicker-next {
    display: inline-block;
    font-family: FontAwesome;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    background-color:none;
    background-repeat: no-repeat;
    position: relative;
    color: transparent !important;
}
.ui-datepicker-prev {
    float: left;
    background-position: center -30px;
}
.ui-datepicker-next {
    float: right;
    background-position: center 0px;
}

.ui-datepicker-prev:before {
  content: "\f053";
  display: block;
  color: rgba(0,0,0,.3) !important;

}

.ui-datepicker-next:before {
  content: "\f054";
  display: block;
  color: rgba(0,0,0,.3) !important;

}

.ui-datepicker-year{
  font-weight: normal;
}

.ui-datepicker-calendar {
  border-collapse:collapse;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  background: #FAFAFA;
  font-family: "Helvetica";
}
.ui-datepicker-calendar th{
	text-align: center;
}
.ui-datepicker td a{
  text-decoration: none;
}
.ui-datepicker td a, .ui-datepicker td span{
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 10px 0;
  color: #333;
}

.ui-datepicker td span{
  color: #BBB;
}

.ui-datepicker .ui-state-active {
  background: #E65;
  color: #fff;
}

.ui-datepicker td.past {
  color:#BBB;
}

.ui-datepicker a:hover {
  background: #ef9085;
  color: #FFF;
}
.rsv_date {
  display: none;
}
