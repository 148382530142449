@charset "UTF-8";
#modalEvaluate {
	background: #fff;
    padding: 30px 36px 0px;
    top: 18%;
    position: relative;
	width: 640px;
	height: 250px;
	margin: 0 auto;
	h3 {
		font-size: 1.2em;
	}
}

#userComment{
	.comment_detail {
		font-size: 14px;
		color: #333;
	}
	.comment_user {
		font-size: 14px;
		color: #222;
	}
	.comment_time {
		font-size: 12px;
		margin-left: 15px;
		color: #999;
	}
	.star {
		color: $baseColor;
	}
	.align_center img {
		margin-right: 5px;	
	}
}
