@charset "UTF-8";
a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover{
	background-color: white!important;
}
h4.list-group-item-heading{
	font-size: 1.2em;
}
.list-group-item-text{
	font-size: 0.8em;
}
.list-group-item-text.cutsom_height{
	height: 3rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
}
.bg-overlay{
	background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("../img/bg-list1.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	color: #fff;
	height: 450px;
	padding-top: 50px;
}
#list{
	#sideSearchCate{
		margin-top: 10px;
		a{
			padding: 5px 15px;
			width: 100%;
			height: 100%;
			display: block;
			color: $baseColor;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.panel{
		min-width: 240px;
		margin-right: 30px;
		.panel-heading{
			border-bottom: 1px;
		}
	}
	#ableCards {
		.list-group-item{
			height: inherit;
			display: block;
			position: relative;
			&:after {
				font-family: FontAwesome;
				display: block;
				position: absolute;
				top: 2px;
				left: 3px;
				font-size: 12px;
			}
			&.rsv_ok:after {
				content: '\f10c';
				color: #95b483;
			}
			&.rsv_ng:after {
				content: "\f00d";
				color: #d8856a;
			}
		}
	}
	.list-group-item{
		height: 215px;
	    align-items: center;
		.price{
			font-weight: bold;
		}
		.rating{
			margin-left: 5px;
			.star{
				color: $baseColor;
				font-size: 14px;
			}
		}
		.last-login{
			font-size: 0.8rem;
			padding-left: 4px;
			color: #6a6a6a;
			.is-online {
				font-size: 0.7rem;
				padding-right: 2px;
				color: chartreuse;
			}
		}
		.media{
			width: 20%;
			float: left;
		}
		.list-grounp-center{
			float: left;
			margin: 5px 2%;
			width: 56%;
		}
		.list-group-right{
			float: right;
			width: 20%;
		}
		.btn-block{
			margin-top: 5px;
			margin-bottom: 5px;
			&:nth-child(3):hover {
				color: #000;
			}
		}
	}

}
