@charset "UTF-8";
#becomeMentorStep {
	text-align: center;
	margin: 30px auto 50px;
	&>li {
		min-height: 300px;
		background: #fff;
		border: 8px solid #f6f7fa;
		margin-bottom: 10px;
		padding: 20px;
	}
	h2 {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			display: block;
			margin: 0 5px;
		}
	}
	h4 {
		text-align: center;
		font-weight: bold;
	}
	p {
		font-size: 12px;
		margin: 10px; 
	}
	a {
		background: $accentColor;
		color: #fff;
		padding: 7px 15px;
		border-radius: 20px;
		box-shadow: 1px 1px 0px #9c9c9c;
		&:hover {opacity: 0.7;}
	}
	.img_back {
		width: 140px;
		background: #C6D7E3;
		height: 140px;
		display: flex;
		align-items: center;
		margin: 0 auto;
		img {
			margin: 0 auto;
		}
	}
	.mentor_column {
		margin: 30px 20px;
		li {
			min-height: 0;
			padding: 5px;
			border: 2px solid #94a1a7;
			margin: 5px;
			a {
				background: none;
				color: $baseColor;
				box-shadow: none;
			}
		}
	}
	.form-group {
		text-align: left;
	}
	textarea {
		height: 120px;
	}
	input[type=file]{
		font-size: 12px;
	    margin-left: 32%;
		margin-bottom: 5px;
	}
	#proof{
		.form-group{
			text-align: center;
		}
	}
	.proof-docs{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-around;
		width: 455px;
	    margin: 0 auto;
		li{
			background-color: #ccc;
			padding: 3px 8px;
			border-radius: 3px;
			font-size: 12px;
			color: #565656;
			width: 145px;
		    margin-top: 3px;
		}
	}
}

#mentorMerit {
	width: 80%;
	background: #fbfbd6;
	margin: 30px auto 40px;
	padding: 40px 35px;
	border-radius: 15px;
	li {
		&:first-child {margin-top: 10px;}
		margin-bottom: 5px;
		list-style-type: none;
		margin: 12px;
		font-weight: bold;
		.merit_no {
			background-size: cover;
			height: 25px;
			display: inline-grid;
			width: 25px;
			color: #fff;
			align-items: center;
			text-align: center;
			margin-right: 10px;
			content: "";
			background-image: url('../img/merit.png');
		}
	}
}

#bocomeMentorBtn {
	margin: 0 auto 40px;
    display: block;
    background: #1c6788;
    border-radius: 50px;
    padding: 15px 20px;
    width: 400px;
    color: white;
	&:hover {
		opacity: 0.8;
	}
}
