@charset "UTF-8";
.reword-type {
  background: #22a66f;
  padding: 3px;
  font-size: 13px;
  margin-right: 5px;
  position: relative;
  top: -1px;
  color: #fff;
}
#questions {
  .list-group-item {
    .ribbon {
      position: absolute;
      left: -5px; top: -5px;
      z-index: 1;
      overflow: hidden;
      width: 75px; height: 75px;
      text-align: right;
      span {
        font-size: 12px;
        font-weight: bold;
        color: #FFF;
        text-transform: uppercase;
        text-align: center;
        line-height: 22px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 100px;
        display: block;
        background: $red;
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px; left: -21px;
        &:before {
          content: "";
          position: absolute; left: 0px; top: 100%;
          z-index: -1;
          border-left: 3px solid darken($red,20%);
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-top: 3px solid darken($red,20%);
        }
        &::after {
          content: "";
          position: absolute; right: 0px; top: 100%;
          z-index: -1;
          border-left: 3px solid transparent;
          border-right: 3px solid darken($red,20%);
          border-bottom: 3px solid transparent;
          border-top: 3px solid darken($red,20%);
        }
      }
      &.solved span{
        color: #888;
        background: #ccc;
        &:before {
          border-left: 3px solid darken(#ccc,20%);
          border-top: 3px solid darken(#ccc,20%);
        }
        &:after {
          border-right: 3px solid darken(#ccc,20%);
          border-top: 3px solid darken(#ccc,20%);
        }
      }
    }//.ribon
    .list-grounp-center{
      margin: 2%;
      width: 76%;
      h4.list-group-item-heading {
        margin-left: 15px;
      }
      .answer-mentor {
        position: absolute;
        bottom: 10px;
        img {
          margin-right: 5px;
        }
      }
    }
    .list-group-right-bottom{
      font-size: 13px;
      color: #444;
      margin-top: 20px;
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }//list-group-item
}
//回答ボタン
.iKnow div{
  background: #fff;
  width: 600px;
  margin: 0 auto;
  line-height: 80px;
  top: 50%;
  margin-top: -130px;
  position: relative;
  border-radius: 5px;
}
#list {
  .input-group {
    display: block;
  }
  input[name="search-q"] {
    width: 170px;
  }
  #search-submit {
    width: 38px;
    position: relative;
    top: -2px;
    height: 28px;
    line-height: 15px;
  }
}
#questionDetail {
  .status {
    background-color: $red;
    display: inline-block;
    color: #fff;
    padding: 1px 5px;
    margin-bottom: 8px;
    &.solved {
      color: #888;
      background: #ccc;
    }
  }
  h2 {
    display: flex;
    align-items: center;
    margin: 0px auto 5px;
    img{
      margin-right: 5px;
    }
  }
  .question-contents {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  .take-answer {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    ul{
      border-bottom: 1px solid #ddd;
      margin-bottom: 7px;
    }
    .answer-msg {
      font-size: 14px;
      color: #333;
      margin-top: 5px;
      margin-left: 3px;
    }
  }
  .price {
    font-weight: bold;
    margin-left: 5px;
    font-size: 17px;
  }
  .btn-solved {
    text-align: center;
    color: #333;
    background-color: #fff;
    border: 1px solid transparent;
    border-color: #ccc;
    line-height: 2.5;
      border-radius: 5px;
  }
  .question-date-detail{
    font-size: 13px;
    color: #444;
  }
}
