@charset "UTF-8";
//bankInfo
#myBalance {
	width: 250px;
	height: 38px;
    right: 50px;
    position: absolute;
    background: #fff;
    border: 1px solid #989898;
    border-radius: 3px;
	h4,p {
		padding: 8px 10px 3px;
		height: 100%;
	}
	h4 {
		background-color: #d4d4d4;
	}
	p {
		text-align: right;
	}
}
